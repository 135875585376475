import Nr from "@/utils/NetworldRequest";
const request = new Nr().getAxios();
function getBaseUrl(serviceName) {
  return new Nr().getBaseUrl(serviceName);
}
export default {
  // 闭环管理,
  getClosedLoopList({ params, platform }) {
    return request({
      url: "/jjt/closedLoop/list",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 销售线索管理-按收集渠道
  getSaleClueListByCollect({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/collect/list",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 销售线索管理-按流量来源
  getSaleClueListByFlow({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/flux/list",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 销售线索管理-按流量来源统计
  getSaleClueCountByFlow({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/flux/sum",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 销售线索管理-按意向购车地
  getSaleClueListByArea({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/areaPreference/list",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 销售线索管理-按意向购车地统计
  getSaleClueCountByArea({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/areaPreference/sum",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 销售线索管理-按意向车系
  getSaleClueListBySeries({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/series/list",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 销售线索管理-按意向车系统计
  getSaleClueCountBySeries({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/series/sum",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 获取购车地详情
  getDetailByArea({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/areaPreference/detail",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 获取车系详情
  getDetailBySeries({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/series/pie",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 活动闭环管理,
  getActivityClosedLoopList({ params, platform }) {
    return request({
      url: "/jjt/closedLoop/list/new",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 闭环管理-意向分析图表
  getClosedLoopChart({ params, platform }) {
    return request({
      url: "/jjt/closedLoop/analyzeChart",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 选择分组
  getSortGroup(params) {
    return request({
      url: "/jjt/group/selectAllGroup",
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params,
    });
  },
  // 新建分组
  addShopGroup(data) {
    return request({
      url: "/jjt/group",
      method: "post",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      data,
    });
  },
  // 收藏分组
  addCollectGrounp(data) {
    return request({
      url: "/jjt/group/facoriteDealer",
      method: "post",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      data,
    });
  },
  // 活动列表
  getActivityList({ platform }) {
    return request({
      url: "/jjt/business/policy/assessment/activity-list",
      baseURL: getBaseUrl(platform),
      method: "get",
    });
  },
  // 区域列表
  getAreaList() {
    return request({
      url: `/jjt/dealer/dealers/region-list`,
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then((res) => {
      let list = res.data.data.region_list || [];
      if (res.data.data.type == 10) {
        list.unshift({
          id: "",
          name: "不限大区",
        });
      }
      list.map((item) => {
        item.value = item.id;
        item.label = item.name;
      });
      return list;
    });
  },
  // 查询单条活动详情
  getActivityDetail({ params, platform }) {
    return request({
      url: "/dealer/topic/one",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 查询活动闭环管理线索数
  getClueCount({ params, platform }) {
    return request({
      url: "/jjt/closedLoop/clue-count",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 查询闭环管理线索数
  getClueCountSum({ params, platform }) {
    return request({
      url: "/jjt/closedLoop/clue-count/sum",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  // 查询线索管理销售线索数
  getSaleClueCountSum({ params, platform }) {
    return request({
      url: "/jjt/cluesManagement/clue-count/sum",
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    });
  },
  getXhsClueList({ params, platform }) {
    return request({
      url: "/jjt/xhs/clueManagement/list",
      method: "get",
      baseURL: getBaseUrl("agg"),
      params,
    });
  },
  getXhsClueSum({ params, platform }) {
    return request({
      url: "/jjt/xhs/clueManagement/sum",
      method: "get",
      baseURL: getBaseUrl("agg"),
      params,
    });
  },
};
