import api from "@/api/closedLoop";
import { message } from "ant-design-vue";
import { getPrincipalId } from "@/utils/auth";
import { getRegionVillagePermissionListApi } from "@/api/bff/region";
const {
  getClosedLoopList,
  getSaleClueListByCollect,
  getSaleClueListByFlow,
  getSaleClueCountByFlow,
  getSaleClueListByArea,
  getSaleClueCountByArea,
  getSaleClueListBySeries,
  getSaleClueCountBySeries,
  getActivityClosedLoopList,
  getClosedLoopChart,
  getSortGroup,
  getActivityList,
  getAreaList,
  getActivityDetail,
  getClueCount,
  getClueCountSum,
  getSaleClueCountSum,
  getXhsClueList,
  getXhsClueSum,
} = api;
export default {
  namespaced: true,

  state: {
    tableData: [],
    countData: {
      total: 0,
    },
    activityTableData: [],
    groupList: [],
    tableLoading: false,
    activityTaleLoading: false,
    tablePagination: {
      total: 0,
    },
    activityTablePagination: {
      total: 0,
    },
    multipleData: [],
    activityList: [],
    areaList: [],
    chartData: {
      city: [],
      province: [],
    },
    activityDetail: {},
    clueCount: {},
    clueCountSum: {},
    saleClueCountSum: {},
    regionList: [],
  },
  actions: {
    getClosedLoopList({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "tableData", data: [] });
      return getClosedLoopList({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          let pagination = {
            total: res.data.data.pagination.total,
          };
          commit("changeState", {
            name: "tableData",
            data: res.data.data.list,
          });
          commit("changeState", { name: "tablePagination", data: pagination });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getSaleClueListByCollect({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "tableData", data: [] });
      return getSaleClueListByCollect({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          let pagination = {
            total: res.data.data.pagination.total,
          };
          commit("changeState", {
            name: "tableData",
            data: res.data.data.list,
          });
          commit("changeState", { name: "tablePagination", data: pagination });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getXhsClueList({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "tableData", data: [] });
      return getXhsClueList({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          let pagination = {
            total: res.data.data.pagination.total,
          };
          commit("changeState", {
            name: "tableData",
            data: res.data.data.list,
          });
          commit("changeState", { name: "tablePagination", data: pagination });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getXhsClueSum({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "countData", data: {} });
      return getXhsClueSum({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          commit("changeState", {
            name: "countData",
            data: {
              total: res.data.data.sales_clue_count,
              ...res.data.data,
            },
          });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getSaleClueListByFlow({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "tableData", data: [] });
      return getSaleClueListByFlow({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          let pagination = {
            total: res.data.data.pagination.total,
          };
          commit("changeState", {
            name: "tableData",
            data: res.data.data.list,
          });
          commit("changeState", { name: "tablePagination", data: pagination });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getSaleClueCountByFlow({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "countData", data: {} });
      return getSaleClueCountByFlow({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          commit("changeState", {
            name: "countData",
            data: res.data.data,
          });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getSaleClueListByArea({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "tableData", data: [] });
      return getSaleClueListByArea({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          let pagination = {
            total: res.data.data.pagination.total,
          };
          commit("changeState", {
            name: "tableData",
            data: res.data.data.list,
          });
          commit("changeState", { name: "tablePagination", data: pagination });
        } else {
          message.warning({
            content: res.data.msg,
            duration: 3,
          });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getSaleClueCountByArea({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "countData", data: {} });
      return getSaleClueCountByArea({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          commit("changeState", {
            name: "countData",
            data: res.data.data,
          });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getSaleClueListBySeries({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "tableData", data: [] });
      return getSaleClueListBySeries({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          let pagination = {
            total: res.data.data.pagination.total,
          };
          commit("changeState", {
            name: "tableData",
            data: res.data.data.list,
          });
          commit("changeState", { name: "tablePagination", data: pagination });
        } else {
          message.warning({
            content: res.data.msg,
            duration: 3,
          });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getSaleClueCountBySeries({ commit }, payload) {
      let { params, platform } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      commit("changeState", { name: "countData", data: {} });
      return getSaleClueCountBySeries({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          commit("changeState", {
            name: "countData",
            data: res.data.data,
          });
        }
        commit("changeState", { name: "tableLoading", data: false });
      });
    },
    getActivityClosedLoopList({ commit }, payload) {
      let { platform, params } = payload;
      commit("changeState", { name: "activityTaleLoading", data: true });
      return getActivityClosedLoopList({ params, platform }).then((res) => {
        if (res.data.code == 0) {
          let pagination = {
            total: res.data.data.pagination.total,
          };
          commit("changeState", {
            name: "activityTableData",
            data: res.data.data.list,
          });
          commit("changeState", {
            name: "activityTablePagination",
            data: pagination,
          });
        }
        commit("changeState", { name: "activityTaleLoading", data: false });
      });
    },
    getClosedLoopChart({ commit }, payload) {
      let { params, platform } = payload;
      return getClosedLoopChart({
        params,
        platform,
      })
        .then((res) => {
          console.log("chart", res);
          let defaultData = {
            city: [],
            province: [],
          };
          commit("changeState", {
            name: "chartData",
            data: res.data.data || defaultData,
          });
        })
        .catch((err) => {
          commit("changeState", {
            name: "chartData",
            data: res.data.data || defaultData,
          });
        });
    },
    getSortGroup({ commit }) {
      return getSortGroup().then((res) => {
        console.log("分组", res);
        commit("changeState", { name: "groupList", data: res.data.data });
      });
    },
    // 相关经销商分组
    getDealerGroup({ commit }, payload) {
      let { params } = payload;
      return getSortGroup(params).then((res) => {
        commit("changeState", { name: "multipleData", data: res.data.data });
      });
    },
    getActivityList({ commit }, payload) {
      let { platform } = payload;
      return getActivityList({
        platform,
      }).then((res) => {
        if (res.data.code == 0) {
          commit("changeState", { name: "activityList", data: res.data.data });
        }
      });
    },
    getAreaList({ commit }, payload) {
      commit("changeState", { name: "areaList", data: [] });
      return getAreaList().then((res) => {
        console.log("getAreaList res", res);
        commit("changeState", { name: "areaList", data: res });
      });
    },
    getActivityDetail({ commit }, payload) {
      let { params, platform } = payload;
      return getActivityDetail({
        params,
        platform,
      }).then((res) => {
        console.log("getActivityDetail res", res);
        commit("changeState", { name: "activityDetail", data: res.data.data });
      });
    },
    getClueCount({ commit }, payload) {
      let { params, platform } = payload;
      return getClueCount({
        params,
        platform,
      }).then((res) => {
        console.log("getClueCount res", res);
        commit("changeState", { name: "clueCount", data: res.data.data });
      });
    },
    getClueCountSum({ commit }, payload) {
      let { params, platform } = payload;
      return getClueCountSum({
        params,
        platform,
      }).then((res) => {
        console.log("getClueCount res", res);
        commit("changeState", { name: "clueCountSum", data: res.data.data });
      });
    },
    getSaleClueCountSum({ commit }, payload) {
      let { params, platform } = payload;
      return getSaleClueCountSum({
        params,
        platform,
      }).then((res) => {
        console.log("getSaleClueCountSum res", res);
        commit("changeState", {
          name: "saleClueCountSum",
          data: res.data.data,
        });
      });
    },
    getRegionList({ commit }, payload) {
      commit("changeState", { name: "regionList", data: [] });
      return getRegionVillagePermissionListApi({
        principalId: getPrincipalId(),
      }).then((res) => {
        commit("changeState", {
          name: "regionList",
          data: res.data.data || [],
        });
      });
    },
  },
  mutations: {
    changeState(state, payload) {
      let { data, name } = payload;
      state[name] = data;
    },
  },
};
